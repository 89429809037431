import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { Trans } from "@lingui/macro";
import { LocalizedLink, useLocalization } from "gatsby-theme-i18n";
import parse from "html-react-parser";
import _ from "lodash";
import {
  faCalendar,
  faClock,
  faLocationDot,
  faFilePdf,
  faArrowDownToBracket,
  faChevronDown,
  faKeynote,
  faChevronUp,
  faLink,
} from "@fortawesome/pro-light-svg-icons";
import moment from "moment";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Carousel as ReactCarousel } from "react-responsive-carousel";
import { colors } from "../theme";
import CustomizedIcon from "../components/CustomizedIcon";
import Container from "../components/Container";
import Hero from "../components/Hero";
import NewLayout from "../components/NewLayout";
import events from "../cms/events/events";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Modal from "../components/Modal";
import logoPositive from "../images/logo.png";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/it";

const LITERALS = (key) =>
  ({
    agenda: <Trans>Agenda</Trans>,
    speakers: <Trans>Speakers</Trans>,
    documents: <Trans>Documents</Trans>,
    media: <Trans>Media</Trans>,
    sponsors: <Trans>Sponsors</Trans>,
  }[key]);

const getLabelKind = (kind) =>
  ({
    Presencial: <Trans>Presential</Trans>,
    Webinar: <Trans>Webinar</Trans>,
  }[kind]);

function Event({ pageContext }) {
  const { locale } = useLocalization();
  moment.locale(locale);
  if (!events[locale]?.[pageContext.slug]) return null;
  const event = events[locale][pageContext.slug];
  if (!_.isNil(event.attributes) && _.isNil(event.attributes.content)) {
    event.attributes.content = {
      speakers: null,
      documents: null,
      sponsors: null,
      media: null,
    };
  }
  const {
    attributes: {
      agenda,
      hero: { title, description, image, filter_color, logo },
      info: {
        start_date,
        end_date,
        time,
        kind,
        webinar_link,
        address,
        register_link,
      },
      content: {
        speakers,
        documents,
        sponsors,
        media,
        past_video_link,
        contact_content,
        legal_content,
      },
      order_documents,
      order_media,
      order_speakers,
      order_sponsors,
      order_agenda,
    },
  } = event;
  const [gallery, setGallery] = React.useState([]);
  const [currentMedia, setCurrentMedia] = React.useState(
    media?.data.slice(0, 4),
  );
  const [showSpeaker, setShowSpeaker] = React.useState(null);
  const end = moment(end_date);
  const today = moment();
  const isPastEvent = end_date && end.diff(today) < 0;

  let sections = {
    agenda: order_agenda,
    speakers: order_speakers,
    documents: order_documents,
    media: order_media,
    sponsors: order_sponsors,
  };
  sections = _.fromPairs(_.sortBy(_.toPairs(sections), 1));
  const headers = [
    isPastEvent
      ? []
      : address
      ? { title: address, icon: faLocationDot }
      : webinar_link
      ? {
          title: (
            <a href={webinar_link} target="_blank" rel="noreferrer">
              <Trans>Event Link</Trans>
            </a>
          ),
          icon: faLink,
        }
      : [],
    {
      title: moment(start_date).format("dddd, DD MMMM YYYY").replace(/\b\w/g, (c) => c.toUpperCase()),
      icon: faCalendar,
    },
    {
      title: time,
      icon: faClock,
    },
  ];

  const logo_url = (logo?.data?.attributes?.formats?.large?.url || logo?.data?.attributes?.url);

  return (
    <>
      <NewLayout withoutNavbar>
        <Hero
          height={500}
          kind={getLabelKind(kind)}
          title={title}
          description={description}
          filterColor={filter_color}
          backgroundImage={
            image?.data?.attributes?.url || image?.data?.attributes?.formats?.large?.url
          }
          // startDate={start_date}
          // time={time}
          {...(!isPastEvent && register_link
            ? {
                buttonText: <Trans>Register</Trans>,
                buttonPath: register_link,
              }
            : {})}
        />
        <LocalizedLink to="/">
          <StyledLogo
            src={_.isNil(logo_url) ? logoPositive : logo_url}
            style={_.isNil(logo_url) ? {} : { maxWidth: "160px" }}
          />
        </LocalizedLink>
        <Box py={{ sm: 2 }} sx={{ background: "rgba(187, 188, 188, .2)" }}>
          <Container>
            <Grid container>
              {headers
                .filter((item) => item.title)
                .map(({ title, icon }) => (
                  <Figure item xs={12} md={isPastEvent ? 6 : 4}>
                    <CustomizedIcon
                      icon={icon}
                      color={colors.blue.main}
                      size={20}
                      padding={1.5}
                      sx={{ margin: "0 auto" }}
                    />
                    <Typography mt={1}>{title}</Typography>
                  </Figure>
                ))}
            </Grid>
          </Container>
        </Box>
        <Container>
          <Stack spacing={6} my={6}>
            {Object.keys(sections).map((key) =>
              (key === "speakers" && !_.isNil(speakers) && !_.isEmpty(speakers.data) ? (
                <Box>
                  <Typography variant="h4">
                    <Trans>{LITERALS(key)}</Trans>
                  </Typography>
                  <Grid container spacing={2} mt={1}>
                    {speakers.data.map(
                      (
                        { attributes: { name, position, company, photo, bio } },
                        index,
                      ) => {
                        let isBioEmpty = _.isEmpty(bio);
                        if (!isBioEmpty) {
                          const reactElement = [parse(bio)].flat();
                          isBioEmpty = reactElement.every((item) => !item.props.children);
                        }
                        return (
                          <Grid item xs={12} sm={6} md={4}>
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="start"
                              sx={{ ...(!isBioEmpty && { cursor: "pointer" }) }}
                              onClick={() =>
                                setShowSpeaker(
                                  !isBioEmpty
                                    ? speakers.data[index]?.attributes
                                    : null,
                                )}
                            >
                              {!photo.data ? (
                                <CustomizedIcon
                                  icon={faKeynote}
                                  color={colors.red.main}
                                  size={20}
                                  padding="10px"
                                />
                              ) : (
                                <Box
                                  sx={{
                                    height: "50px",
                                    width: "50px",
                                    minWidth: "50px",
                                    minHeight: "50px",
                                    display: "block",
                                    backgroundSize: "cover",
                                    backgroundImage: `url(${photo.data.attributes.url})`,
                                    backgroundPosition: "start",
                                    borderRadius: "50%",
                                  }}
                                />
                              )}
                              <Box>
                                <Typography fontWeight={600} fontSize={20}>
                                  {name}
                                </Typography>
                                <Typography>{position}</Typography>
                                <Typography sx={{ fontStyle: "italic" }}>
                                  {company}
                                </Typography>
                              </Box>
                            </Stack>
                          </Grid>
                        );
                      },
                    )}
                  </Grid>
                </Box>
              ) : key === "media" && !_.isEmpty(currentMedia) ? (
                <Box>
                  <Typography variant="h4">
                    <Trans>{LITERALS(key)}</Trans>
                  </Typography>
                  <Grid container spacing={2} mt={1}>
                    {currentMedia.map(({ attributes: { formats } }, index) => (
                      <Grid item xs={12} sm={6} md={3}>
                        <Box
                          sx={{
                            height: "200px",
                            display: "block",
                            backgroundSize: "contain",
                            backgroundImage: `url(${formats.thumbnail.url})`,
                            backgroundPosition: "center",
                            borderRadius: "5px",
                            cursor: "pointer",
                            backgroundRepeat: "no-repeat",
                          }}
                          onClick={() =>
                            setGallery(
                              index === 0
                                ? media.data
                                : media.data
                                    .slice(index)
                                    .concat(media.data.slice(0, index)),
                            )}
                        />
                      </Grid>
                    ))}
                    <Grid item xs={12} alignItems="center">
                      <Stack direction="row" spacing={1} justifyContent="end">
                        <Typography>
                          {media.data.length === currentMedia.length ? (
                            <Trans>View less</Trans>
                          ) : (
                            <Trans>View more</Trans>
                          )}
                        </Typography>
                        <Box
                          sx={{ cursor: "pointer" }}
                          onClick={() =>
                            setCurrentMedia(
                              media.data.length === currentMedia.length
                                ? media.data.slice(0, 4)
                                : media.data,
                            )}
                        >
                          <FontAwesomeIcon
                            icon={
                              media.data.length === currentMedia.length
                                ? faChevronUp
                                : faChevronDown
                            }
                          />
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              ) : key === "documents" && !_.isNil(documents) && !_.isEmpty(documents.data) ? (
                <Box>
                  <Typography variant="h4">
                    <Trans>{LITERALS(key)}</Trans>
                  </Typography>
                  <Grid container spacing={2} mt={1}>
                    {documents.data.map(({ attributes: { name, url } }) => (
                      <Grid item xs={12} sm={6} md={4}>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <CustomizedIcon
                              icon={faFilePdf}
                              color={colors.red.main}
                              size={20}
                              padding="10px"
                            />
                            <Typography>{name.replaceAll("_", " ")}</Typography>
                          </Stack>
                          <Box>
                            <FontAwesomeIcon
                              onClick={() => window.open(url, "_blank")}
                              icon={faArrowDownToBracket}
                              color={colors.red.main}
                              style={{
                                width: "20px",
                                height: "20px",
                                cursor: "pointer",
                              }}
                            />
                          </Box>
                        </Stack>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ) : key === "sponsors" && !_.isNil(sponsors) && !_.isEmpty(sponsors.data) ? (
                <Box>
                  <Typography variant="h4">
                    <Trans>{LITERALS(key)}</Trans>
                  </Typography>
                  <Grid container spacing={2} mt={1}>
                    {_.orderBy(sponsors.data, "attributes.name", "asc").map(
                      ({ attributes: { logo, website } }, index) => (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={index > 3 ? 4 : 3}
                          sx={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            minHeight: "100px",
                          }}
                          onClick={() => window.open(website, "_blank")}
                        >
                          <img
                            alt="logo"
                            style={{ width: "60%" }}
                            src={logo.data.attributes.formats?.thumbnail?.url || logo.data.attributes.url}
                            // style={{ width: "100%", objectFit: "cover" }}
                          />
                        </Grid>
                      ),
                    )}
                  </Grid>
                </Box>
              ) : key === "agenda" && !_.isEmpty(agenda) && (
                <Stack spacing={2}>
                  <Typography variant="h4">
                    <Trans>{LITERALS(key)}</Trans>
                  </Typography>
                  <Stack spacing={1}>
                    {agenda.map(({ hour, title, description }) => (
                      <Stack spacing={1} direction="row" alignItems="baseline">
                        <Box
                          sx={{
                            width: "47px",
                            height: "47px",
                            minWidth: "47px",
                            minHeight: "47px",
                            display: "grid",
                            borderRadius: "50%",
                            placeContent: "center",
                            backgroundColor: (theme) =>
                              theme.palette.primary.light,
                          }}
                        >
                          <Typography fontSize={14} color="primary.main">
                            {hour}
                          </Typography>
                        </Box>
                        <Stack spacing={0}>
                          <Typography variant="h5">{title}</Typography>
                          {description && (
                            <StyledContent>{parse(description)}</StyledContent>
                          )}
                        </Stack>
                      </Stack>
                    ))}
                  </Stack>
                </Stack>
              )),
            )}
            {past_video_link && ( // isPastEvent && (
              <Box>
                <Typography variant="h4">
                  <Trans>Video</Trans>
                </Typography>
                <VideoContainer mt={2} display="flex" justifyContent="center">
                  <iframe
                    title="event_video"
                    src={`${past_video_link}?h=7257be9ae2`}
                    frameBorder="0"
                    webkitallowfullscreen
                    mozallowfullscreen
                    allowfullscreen
                  />
                </VideoContainer>
              </Box>
            )}
            <Box sx={{ "*": { margin: 0 } }}>
              {contact_content && parse(contact_content)}
              {legal_content && (
                <Box mt={3}>
                  <Typography variant="small">
                    {parse(legal_content)}
                  </Typography>
                </Box>
              )}
            </Box>
          </Stack>
        </Container>
      </NewLayout>
      {!_.isEmpty(gallery) && (
        <Modal
          open
          onClose={() => setGallery([])}
          backgroundColor="white"
          sx={{ padding: "0 !important" }}
        >
          <StyledReactCarousel
            showArrows
            showStatus={false}
            showThumbs={false}
            swipeable
            emulateTouch
            infiniteLoop
          >
            {gallery.map(({ attributes: { url } }, index) => (
              <Box
                key={`slide${index}`}
                sx={{
                  width: "100%",
                  height: "600px",
                  backgroundImage: `url(${url})`,
                  backgroundSize: "contain",
                  borderRadius: "5px",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              />
            ))}
          </StyledReactCarousel>
        </Modal>
      )}
      {!_.isEmpty(showSpeaker) && (
        <Modal
          open
          onClose={() => setShowSpeaker(null)}
          backgroundColor="white"
          sx={{
            maxWidth: {
              xs: "90% !important",
              sm: "70% !important",
              md: "50% !important",
            },
            maxHeight: "90% !important",
            overflowY: "auto",
          }}
        >
          <Box>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              alignItems="center"
            >
              <Box>
                {!showSpeaker.photo.data ? (
                  <CustomizedIcon
                    icon={faKeynote}
                    color={colors.red.main}
                    size={40}
                    padding="15px"
                  />
                ) : (
                  <Box
                    sx={{
                      height: "100px",
                      width: "100px",
                      minWidth: "100px",
                      minHeight: "100px",
                      display: "block",
                      backgroundSize: "cover",
                      backgroundImage: `url(${showSpeaker.photo.data.attributes.url})`,
                      backgroundPosition: "start",
                      borderRadius: "50%",
                    }}
                  />
                )}
              </Box>
              <Box
                paddingX={{ xs: 4, sm: 0 }}
                textAlign={{ xs: "center", sm: "start" }}
              >
                <Typography fontWeight={600} fontSize={20}>
                  {showSpeaker.name}
                </Typography>
                <Typography>{showSpeaker.position}</Typography>
                <Typography sx={{ fontStyle: "italic" }}>
                  {showSpeaker.company}
                </Typography>
              </Box>
            </Stack>
            <Box mt={{ xs: 2, sm: 4 }}>
              <Typography>{parse(showSpeaker.bio)}</Typography>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
}

const StyledLogo = styled.img(
  ({ theme }) => `
  position: absolute;
  top: ${theme.spacing(4)};
  ${theme.breakpoints.down("sm")} {
    left: ${theme.spacing(2)};
  }
  ${theme.breakpoints.up("sm")} {
    left: ${theme.spacing(4)};
  }
`,
);

const VideoContainer = styled(Box)(
  ({ theme }) => `
  > iframe {
    ${theme.breakpoints.down("lg")} {
      min-height: 50vw;
      min-width: 100%;
    }
    ${theme.breakpoints.up("lg")} {
      min-height: 60vh;
      min-width: 100vh;
    }
  }
`,
);

const StyledReactCarousel = styled(ReactCarousel)`
  .carousel .control-dots .dot {
    display: none;
  }
`;

// const StyledBox = styled(Box)`
//   > * strong {
//     font-size: 24px;
//   }
// `;

const Figure = styled(Grid)(
  ({ theme }) => `
  text-align: center;
  padding: ${theme.spacing(2)} ${theme.spacing(6)};
  ${theme.breakpoints.down("md")} {
    &:not(:last-child) {
      border-bottom: solid 1px ${theme.palette.neutral.main};
    }
  }
  ${theme.breakpoints.up("md")} {
    &:not(:last-child) {
      border-right: solid 1px ${theme.palette.neutral.main};
    }
  }
`,
);

export default Event;

export const StyledContent = styled.div`
  /* Basic editor styles */

  > ul {
    padding-left: 18px;
    > li {
      margin-top: 12px;
      > * {
        margin: 0;
      }
    }
  }

  img {
    border-radius: 8px;
    max-width: 100%;
    height: auto;
    margin: ${({ theme }) => theme.spacing(2)} 0;
  }
`;
